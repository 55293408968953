import React, { useEffect, useState } from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import colors from "../assets/styles/colors";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import PromocionModeloSlider from "../components/common/PromocionModeloSlider";
import PromocionesSlider from "../components/common/PromocionesSlider";
import { connect } from "react-redux";
import { getCarline, setSalesgroup } from "../redux/carlines/actions";
import ComponentLoading from "../components/common/ComponentLoading";
import API from "../classes/api";
import Layout from "../components/common/Layout";
import { dataLayerPush } from "../classes/gtm";

const PromocionesModeloPage = ({
	location,
	carline,
	getCarline,
	setSalesgroup,
	salesgroup,
}) => {
	const [carlineCodigo, setCarlineCodigo] = useState(null);
	const [salesgroupCodigo, setSalesgroupCodigo] = useState(null);

	useEffect(() => {
		// onload, parsear de la url: carline y salesgroup
		const pathParts = location.pathname.split("/");
		setCarlineCodigo(pathParts[pathParts.length - 2]);
		setSalesgroupCodigo(pathParts[pathParts.length - 1]);
	}, []);

	useEffect(() => {
		// ya parseamos carline y salesgroup del path, buscar carline en la api
		if (carlineCodigo && salesgroupCodigo) {
			getCarline({
				codigo: carlineCodigo,
				isEmpresa: false,
			});
		}
	}, [carlineCodigo, salesgroupCodigo]);

	useEffect(() => {
		if (carline) {
			// ya cargo el carline de la api, buscar cual es el salesgroup de la url
			setSalesgroup(
				API.findSalesgroupFromCarline(carline, salesgroupCodigo),
			);
		}
	}, [carline]);

	// gtm

	useEffect(() => {
		if (carline && salesgroup) {
			dataLayerPush({
				seccion: "promociones",
				pagina: "salesgroups",
				gama: carline.codigo,
				modelo: salesgroup.codigo,
				utm_content: "",
				modelo_edicion: "",
			});
		}
	}, [carline, salesgroup]);

	return (
		<Layout>
			<main>
				<GlobalStyle />

				{(!carline || !salesgroup) && (
					<StyledSection>
						<StyledSectionInner>
							<ComponentLoading />
						</StyledSectionInner>
					</StyledSection>
				)}

				{carline && salesgroup && (
					<>
						<StyledSection>
							<PromocionModeloSlider
								position="right"
								sliderItems={(() => {
									const banners = API.findBanners(
										carline,
										salesgroup,
										null,
										true,
									);
									const items = banners.map((banner) => {
										const withMoreData = [
											"financiacion-lineal",
											"opcion-futuro",
											"renting",
										];
										if (withMoreData.indexOf(banner.tipo) > -1) {
											return {
												id: banner.id,
												preTitle: `${
													salesgroup.nuevo ? "Nuevo" : ""
												} Audi ${carline.titulo} ${
													salesgroup.titulo
												}`,
												image: banner.imagenes?.secundaria?.l,
												text: banner.trimlevel?.titulo,
												price: `${banner.precio}€/${banner.periodicidad}`,
												priceNote: "Con entrada. IVA incluído.",
												// featuresList: [],
												buttons: (() => {
													if (banner.botones) {
														return banner.botones.map((boton) => {
															return {
																text: boton.etiqueta,
																url: boton.url,
																nuevaVentana:
																	boton.nuevaVentana,
															};
														});
													} else return null;
												})(),
											};
										} else {
											return {
												id: banner.id,
												preTitle: banner.tituloBanner,
												image: banner.imagenes?.secundaria?.l,
												buttons: (() => {
													if (banner.botones) {
														return banner.botones.map((boton) => {
															return {
																text: boton.etiqueta,
																url: boton.url,
																nuevaVentana:
																	boton.nuevaVentana,
															};
														});
													} else return null;
												})(),
											};
										}
									});
									return items;
								})()}
							/>
						</StyledSection>

						<StyledSection>
							<StyledSectionInner>
								<PromocionesSlider
									carline={carline}
									salesgroup={salesgroup}
								/>
							</StyledSectionInner>
						</StyledSection>
					</>
				)}
			</main>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { carline, salesgroup } = state.carlines;
	return {
		carline,
		salesgroup,
	};
};

const mapDispatchToProps = {
	getCarline,
	setSalesgroup,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PromocionesModeloPage);
